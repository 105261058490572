exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-404-page-tsx": () => import("./../../../src/pages/dev-404-page.tsx" /* webpackChunkName: "component---src-pages-dev-404-page-tsx" */),
  "component---src-templates-cellar-page-index-tsx": () => import("./../../../src/templates/CellarPage/index.tsx" /* webpackChunkName: "component---src-templates-cellar-page-index-tsx" */),
  "component---src-templates-contacts-page-index-tsx": () => import("./../../../src/templates/ContactsPage/index.tsx" /* webpackChunkName: "component---src-templates-contacts-page-index-tsx" */),
  "component---src-templates-experiences-page-index-tsx": () => import("./../../../src/templates/ExperiencesPage/index.tsx" /* webpackChunkName: "component---src-templates-experiences-page-index-tsx" */),
  "component---src-templates-gallery-page-index-tsx": () => import("./../../../src/templates/GalleryPage/index.tsx" /* webpackChunkName: "component---src-templates-gallery-page-index-tsx" */),
  "component---src-templates-history-page-index-tsx": () => import("./../../../src/templates/HistoryPage/index.tsx" /* webpackChunkName: "component---src-templates-history-page-index-tsx" */),
  "component---src-templates-home-page-index-tsx": () => import("./../../../src/templates/HomePage/index.tsx" /* webpackChunkName: "component---src-templates-home-page-index-tsx" */),
  "component---src-templates-location-page-index-tsx": () => import("./../../../src/templates/LocationPage/index.tsx" /* webpackChunkName: "component---src-templates-location-page-index-tsx" */),
  "component---src-templates-oil-page-index-tsx": () => import("./../../../src/templates/OilPage/index.tsx" /* webpackChunkName: "component---src-templates-oil-page-index-tsx" */),
  "component---src-templates-package-page-index-tsx": () => import("./../../../src/templates/PackagePage/index.tsx" /* webpackChunkName: "component---src-templates-package-page-index-tsx" */),
  "component---src-templates-packages-page-index-tsx": () => import("./../../../src/templates/PackagesPage/index.tsx" /* webpackChunkName: "component---src-templates-packages-page-index-tsx" */),
  "component---src-templates-policy-page-index-tsx": () => import("./../../../src/templates/PolicyPage/index.tsx" /* webpackChunkName: "component---src-templates-policy-page-index-tsx" */),
  "component---src-templates-restaurant-page-index-tsx": () => import("./../../../src/templates/RestaurantPage/index.tsx" /* webpackChunkName: "component---src-templates-restaurant-page-index-tsx" */),
  "component---src-templates-rooms-page-index-tsx": () => import("./../../../src/templates/RoomsPage/index.tsx" /* webpackChunkName: "component---src-templates-rooms-page-index-tsx" */),
  "component---src-templates-wine-tasting-page-index-tsx": () => import("./../../../src/templates/WineTastingPage/index.tsx" /* webpackChunkName: "component---src-templates-wine-tasting-page-index-tsx" */),
  "component---src-templates-wines-page-index-tsx": () => import("./../../../src/templates/WinesPage/index.tsx" /* webpackChunkName: "component---src-templates-wines-page-index-tsx" */)
}

